import {lory} from 'lory.js';

[].map.call(document.querySelectorAll('.js_slider'), (element, index)=> {
    lory(element, {
		enableMouseEvents: true,
		infinite: 1
    });
});


