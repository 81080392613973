import 'points';
import Jump from 'jump.js';

const scrollTo = new Jump();

[].map.call(document.querySelectorAll('.name'), (nameButton) => {
	nameButton.addEventListener('click', (event) => {
		var container = nameButton.parentNode;
		if (container.className.indexOf('js-name-open') > -1) {
			container.classList.remove('js-name-open');
		} else {
			const openedEl = document.querySelector('.js-name-open');
			container.classList.add('js-name-open');
			// correct the scroll position for the fact that you closed
			// a div above (_if_ if was above the newly selected one)
			scrollTo.jump(container, {
				duration: 500,
				offset: 0,
				callback: () => {
					if (openedEl) {

						if (openedEl.offsetTop - nameButton.offsetTop < 0) {
							window.scrollBy(0, -openedEl.offsetHeight + 50);
						}
						openedEl.classList.remove('js-name-open');

					}
				}
			});
		}
	});
});