// Oembed rewriten to ES2015 without JQuery

[].map.call(document.querySelectorAll('.oembed-video .thumb, .oembed-video .play'),(nameButton) => {
	nameButton.addEventListener('click', (event) => {
		var wrapper = nameButton.parentNode;
		var embed = wrapper.querySelector('iframe');
		
		embed.setAttribute('src',embed.getAttribute('data-src'));
		embed.setAttribute('style','display : block');

		wrapper.querySelector('.play').remove();
		wrapper.querySelector('.thumb').remove();

	});
});

