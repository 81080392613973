import lazysizes from 'lazysizes';

import './oembed.js';
import './slideout.js';
import './gallery.js';


import gumshoe from 'gumshoe';
import smoothScroll from 'smooth-scroll';



gumshoe.init({
    selector: '.menuitem', 
    selectorHeader: '[data-gumshoe-header]', 
    offset: 0,
    activeClass: 'js-menu-active',
    callback: function (nav) {}
});


smoothScroll.init({
    selector: '.menuitem',
    selectorHeader: '[data-scroll-header]',
    speed: 700,
    easing: 'easeInOutCubic', 
    offset: 0, 
    updateURL: true, 
    callback: function ( anchor, toggle ) {} 
});